export default {
  ROTATION: "rotation",
  NEW_ON_GONOODLE: "new on gonoodle",
  TOPICS: "topics",
  FEATURED_ACTIVITIES: "featured activities",
  MEET_THE_CHAMPS: "meet the champs",
  ALL_VIDEOS: "all videos",
  ALL_ACTIVITIES: "all activities",
  ALL_GAMES: "all games",
  YOU_MIGHT_LIKE: "you might like",
  PAIRED_VIDEO: "paired video",
  PAIRED_PRINTABLE: "paired printable",
  DOWNLOAD_BUTTON: "download button",
  REGISTRATION_BACK_BUTTON: "registration back button",
  FEATURED_VIDEOS: "featured videos",
  FEATURED_BLOG_POSTS: "featured blog posts",
  ALL_BLOG_POSTS: "all blog posts",
  EDIT_PROFILE_BUTTON: "edit profile button",
  ADD_PROFILE_BUTTON: "add profile button",
  CREATE_ACCOUNT_BUTTON: "create account button",
  LOGIN_BUTTON: "login button",
  LOGIN_WITH_CLEVER_BUTTON: "login with clever button",
  LOGIN_WITH_CLEVER_LIBRARY: "login with clever library",
  LOGIN_WITH_GOOGLE_BUTTON: "login with google button",
  LOGIN_WITH_GOOGLE_LIBRARY: "login with google library",
  LOGOUT_BUTTON: "Logout button",
  REGISTER_WITH_CLEVER_BUTTON: "register with clever button",
  REGISTER_WITH_GOOGLE_BUTTON: "register with google button",
  FAVORITE_BUTTON: "Favorite button",
  ADD_CLASSROOM_BUTTON: "add classroom button",
  HERO_UNIT: "hero unit",
  CAROUSEL: "carousel hero unit",
  CAROUSEL_NAVIGATION: "carousel hero unit navigation",
  CONNECT_TO_CLEVER: "connect to clever",
  GOOGLE_CLASSROOM_SHARE_BUTTON: "google classroom share button",
  TROPHY_CUP: "trophy cup",
};

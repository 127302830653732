import * as SCREEN from "./events/screen";
import * as CONTENT from "./events/content";
import * as AUTHENTICATION from "./events/authentication";
import * as NEWSLETTER from "./events/newsletter";
import * as CLICKED from "./events/clicked";
import * as AD from "./events/ad";
import * as USER from "./events/user";
import * as CHAMPS from "./events/champs";

const EVENTS_VALIDATION_SCHEMAS = {
  "Activity Viewed": SCREEN.activityViewed,
  "Activity Downloaded": CONTENT.activityDownloaded,
  "SuperNoodle Activity Downloaded": CONTENT.curriculumActivityDownloaded,
  "Announcement banner clicked": CLICKED.announcementBannerClicked,
  "Announcement banner shown": SCREEN.announcementBannerShown,
  "Video Played": CONTENT.videoPlayed,
  "SuperNoodle Video Played": CONTENT.curriculumVideoPlayed,
  "Content Shared": CONTENT.shared,
  "User Identification Modal Viewed":
    AUTHENTICATION.userIdentificationModalViewed,
  "User Identified": AUTHENTICATION.userIdentified,
  "Clever Sign Up Submission": AUTHENTICATION.cleverSignUpSubmission,
  "Google Sign Up Submission": AUTHENTICATION.googleSignUpSubmission,
  "Clever Account Connected to GoNoodle Account":
    AUTHENTICATION.cleverAccountConnected,
  "Google Account Connected to GoNoodle Account":
    AUTHENTICATION.googleAccountConnected,
  "Newsletter Sign Up": NEWSLETTER.newsletterSignUp,
  "Blog Post Viewed": SCREEN.blogPostViewed,
  "Link Clicked": CLICKED.link,
  "Liability Disclaimer Viewed": AD.liabilityDisclaimerViewed,
  "Leaving GoNoodle Bumper Displayed": SCREEN.leavingGoNoodleBumperViewed,
  "Create Account Flow Entered": SCREEN.createAccountFlowEntered,
  "Age Gate Failed": SCREEN.ageGateFailed,
  "Create Account Email Submission": SCREEN.createAccountEmailSubmission,
  "Create Account Profile Selection": SCREEN.createAccountProfileSelection,
  "Create Account Profile Exited": SCREEN.CreateAccountProfileExited,
  "Clever Sign Up Started": AUTHENTICATION.cleverSignUpStarted,
  "Google Sign Up Started": AUTHENTICATION.googleSignUpStarted,
  "Login Flow Entered": SCREEN.loginFlowEntered,
  "Login Succeeded": AUTHENTICATION.loginSucceeded,
  "Login Failed": AUTHENTICATION.loginFailed,
  "Profile Selected": AUTHENTICATION.profileSelected,
  Logout: AUTHENTICATION.logout,
  "Email Preferences Changed": USER.emailPreferencesChanged,
  "Profile Edited": USER.profileEdited,
  "Add Profile Selected": USER.addProfileSelected,
  "Profile Abandoned": USER.profileAbandoned,
  "Profile Deleted": USER.profileDeleted,
  "Profile Created": USER.profileCreated,
  "Clever Classes Synced": USER.cleverClassesSynced,
  "Add to Favorites": CONTENT.addToFavorites,
  "Remove from Favorites": CONTENT.removeFromFavorites,
  "Champ Profile Viewed": CHAMPS.champProfileViewed,
  "Champ Randomizer Used": CHAMPS.champRandomizerUsed,
  "Champ Selected": CHAMPS.champSelected,
  "Unregistered Champ Achievement": CHAMPS.unregisteredChampAchievement,
  "Champ Achievement Printed": CHAMPS.champAchievementPrinted,
  "Reach Champ Achievement": CHAMPS.reachChampAchievement,
  "New Champ Selected": CHAMPS.newChampSelected,
  "Level Up Video": CHAMPS.levelUpVideo,
  "Level Up Video Skipped": CHAMPS.levelUpVideoSkipped,
  "Competency Completed": CONTENT.competencyCompleted,
  "Competency Started": CONTENT.competencyStarted,
  "Element Marked Completed": CONTENT.elementMarkedCompleted,
  "Element Viewed": SCREEN.elementViewed,
  "Carousel Slide Changed": CLICKED.carouselNavigationClicked,
  "Notification Center Opened": CLICKED.NotificationCenterClicked,
  "Game Played": CONTENT.gamePlayed,
  "Badges Shown": SCREEN.badgesShown,
  "Badge Collected": CONTENT.badgeClaimed,
  "Badge Downloaded": CONTENT.badgeDownloaded,
};

function validate(event, properties) {
  const schema = EVENTS_VALIDATION_SCHEMAS[event];
  const { error } = schema.validate(properties);
  return {
    properties,
    event,
    error,
  };
}

export { validate };

import React from "react";
import PropTypes from "prop-types";
import * as PrimitiveTabs from "@radix-ui/react-tabs";
import { twMerge } from "tailwind-merge";

const missingProvider = "Component must be used within <Tabs.Root>";

export const TabsContext = React.createContext({
  get headless() {
    throw new Error(missingProvider);
  },
});

TabsContext.displayName = "TabsContext";

export function Root({
  defaultValue,
  value,
  onValueChange,
  headless = false,
  className,
  children,
  ...delegated
}) {
  const providerValue = React.useMemo(() => ({ headless }), [headless]);

  return (
    <TabsContext.Provider value={providerValue}>
      <PrimitiveTabs.Root
        defaultValue={defaultValue}
        value={value}
        onValueChange={onValueChange}
        className={className}
        {...delegated}
      >
        {children}
      </PrimitiveTabs.Root>
    </TabsContext.Provider>
  );
}

export function List({ loop = true, className, children, ...delegated }) {
  return (
    <PrimitiveTabs.List loop={loop} className={className} {...delegated}>
      {children}
    </PrimitiveTabs.List>
  );
}

export function Trigger({
  value,
  disabled = false,
  className,
  children,
  onPress,
  ...delegated
}) {
  const { headless } = React.useContext(TabsContext);

  return (
    <PrimitiveTabs.Trigger
      value={value}
      disabled={disabled}
      className={
        headless
          ? className
          : twMerge(
              "relative text-white transition-colors duration-200 text-md font-bold rounded-lg px-[10px] py-[5px]",
              "hover:text-mint focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-[-5px] focus-visible:outline-white after:absolute after:left-0 after:bottom-[-5px] after:w-full after:h-[2px] after:bg-transparent after:transition-colors after:duration-200 data-[state=active]:after:bg-white",
              className,
            )
      }
      onClick={onPress}
      {...delegated}
    >
      {children}
    </PrimitiveTabs.Trigger>
  );
}

export function Panel({ value, className, children, ...delegated }) {
  return (
    <PrimitiveTabs.Content value={value} className={className} {...delegated}>
      {children}
    </PrimitiveTabs.Content>
  );
}

Root.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onValueChange: PropTypes.func,
  headless: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

List.propTypes = {
  loop: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Trigger.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  onPress: PropTypes.func,
};

Panel.propTypes = {
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

import React from "react";
import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../../constants";
import api from "../../api";
import useClaimedBadges from "../useClaimedBadges";
import useEarnedBadges from "../useEarnedBadges";

const client = api();

export default function useBadgesQuery(options = {}) {
  const oneHour = 1000 * 60 * 60;
  const {
    data: publishedBadges = [],
    isLoading: isBadgesLoading,
    isError: badgesError,
  } = useQuery([QUERY_KEYS.BADGES], client.getBadges, {
    staleTime: oneHour * 4,
    ...options,
  });
  const { earnedBadges } = useEarnedBadges();
  const { claimedBadges } = useClaimedBadges();

  const badges = React.useMemo(() => {
    const badgeMap = new Map();

    publishedBadges.forEach((badge) => {
      badgeMap.set(badge.id, { ...badge, isEarned: false, isClaimed: false });
    });

    earnedBadges.forEach((badge) => {
      if (badgeMap.has(badge.id)) {
        const existingBadge = badgeMap.get(badge.id);
        badgeMap.set(badge.id, {
          ...existingBadge,
          isEarned: badge.isEarned,
          isClaimed: badge.isClaimed,
        });
      } else {
        badgeMap.set(badge.id, badge);
      }
    });

    claimedBadges.forEach((badge) => {
      if (badgeMap.has(badge.id)) {
        const existingBadge = badgeMap.get(badge.id);
        badgeMap.set(badge.id, {
          ...existingBadge,
          isEarned: badge.isEarned,
          isClaimed: badge.isClaimed,
        });
      } else {
        badgeMap.set(badge.id, badge);
      }
    });

    const badgesArray = Array.from(badgeMap.values()).sort((a, b) => {
      // Prioritize badges that can be claimed (isEarned: true)
      if (a.isEarned && !b.isEarned) return -1;
      if (!a.isEarned && b.isEarned) return 1;

      // For badges within the same group, sort by publishStartsAt (newest first)
      return new Date(b.publishStartsAt) - new Date(a.publishStartsAt);
    });

    return badgesArray;
  }, [publishedBadges, earnedBadges, claimedBadges]);

  return { badges, isBadgesLoading, badgesError };
}

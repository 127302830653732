import React from "react";

import useProfile from "../useProfile";

export default function useClaimedBadges() {
  const {
    profile: {
      claimedBadges: undecoratedClaimedBadges = [],
      earnedBadges = [],
    } = {},
  } = useProfile();

  const claimedBadges = React.useMemo(
    () =>
      undecoratedClaimedBadges
        .map((badge) => ({
          ...badge,
          isEarned: earnedBadges.some(
            (earnedBadge) => earnedBadge.id === badge.id,
          ),
          isClaimed: true,
        }))
        .sort(
          (a, b) => new Date(a.publishStartsAt) - new Date(b.publishStartsAt),
        ),
    [undecoratedClaimedBadges, earnedBadges],
  );

  return {
    claimedBadges,
  };
}

import React from "react";
import { useRouter } from "next/router";
import {
  HeroVideo as PrimitiveHeroVideo,
  Skeleton,
} from "@gonoodle/gn-universe-ui";
import {
  PAGES,
  SECTIONS_TYPES,
  SECTIONS,
} from "@gonoodle/gn-universe-analytics-schema/src/constants";

import { useDataLoader } from "../../hooks";
import { ROUTE_PREFIX } from "../../../../constants";
import { createImgSources } from "../../../../utils/imgSources";
import { WithContentLink } from "../../../../containers";
import { getUtmParamsFromQueryParams } from "../../../../utils/analytics";

const HeroVideoWithLink = WithContentLink(PrimitiveHeroVideo);

export default function HeroVideo({ templateOptions, fetchers }) {
  const router = useRouter();
  const [fetcher] = fetchers;
  const { title, description } = templateOptions;
  const { data, isLoading } = useDataLoader(fetcher);
  const heroVideo = Array.isArray(data) ? data[0] : data;

  if (isLoading || !heroVideo) {
    return (
      <Skeleton className="rounded-lg bg-gray-500 h-[375px] md:h-[405px] lg:h-[675px]" />
    );
  }

  return (
    <HeroVideoWithLink
      title={title}
      description={description}
      source={heroVideo.loopingVideo}
      fallbackImageSource={createImgSources(heroVideo.images.heroImage)}
      heroLogo={createImgSources(heroVideo.images.logo)}
      id={heroVideo.id}
      slug={heroVideo.slug}
      buttonText={heroVideo.buttonText}
      mode={heroVideo.textTheme}
      contentLinkAsBase={`/${ROUTE_PREFIX.VIDEOS}`}
      contentLinkHrefBase={`/${ROUTE_PREFIX.VIDEOS}/[...identity]`}
      linkParams={{
        sourcePage: PAGES.HOME,
        sourcePageType: PAGES.HOME,
        sourceElement: SECTIONS_TYPES.HERO,
        sourceName: heroVideo.type === "rotation" ? SECTIONS.ROTATION : title,
      }}
      events={[
        {
          event: "Link Clicked",
          properties: {
            sourcePage: PAGES.HOME,
            sourcePageType: PAGES.HOME,
            sourceElement: SECTIONS_TYPES.HERO,
            sourceName:
              heroVideo.type === "rotation" ? SECTIONS.ROTATION : title,
            ...getUtmParamsFromQueryParams(router.query),
          },
        },
      ]}
    />
  );
}

import { useQuery } from "@tanstack/react-query";

import { QUERY_KEYS } from "../constants";
import api from "../api";

const client = api();

export default function useUserAccessesQuery(options = {}) {
  const {
    data: accessData,
    isLoading: isUserAccessesLoading,
    isError: userAccessesError,
  } = useQuery([QUERY_KEYS.USER_ACCESSES], client.getUserAccesses, {
    ...options,
  });

  return {
    userAccesses: accessData?.accesses || [],
    isUserAccessesLoading,
    userAccessesError,
  };
}

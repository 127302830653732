import React from "react";

import useBadgesQuery from "../useBadgesQuery";

export default function useBadgeQuery({ id: badgeId, options = {} }) {
  const { badges = [], isBadgesLoading, badgesError } = useBadgesQuery({
    options,
  });

  const badge = React.useMemo(
    () => badges.find(({ id }) => id === badgeId) || null,
    [badges, badgeId],
  );

  return {
    badge,
    isBadgeLoading: isBadgesLoading,
    badgeError: badgesError,
  };
}

import { useRouter } from "next/router";
import { camelizeKeys } from "humps";
import { isEmpty } from "lodash";

export default function useIntendedPath() {
  const router = useRouter();

  const { intendedPath } = camelizeKeys(router.query);

  const redirectToIntendedPath = ({ fallbackPath }) => {
    if (!isEmpty(intendedPath)) {
      router.push(intendedPath);
    } else if (fallbackPath) {
      router.push(fallbackPath);
    }
  };

  return {
    intendedPath,
    redirectToIntendedPath,
  };
}

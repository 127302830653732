import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import * as PrimitiveScrollArea from "@radix-ui/react-scroll-area";

export const Panel = React.forwardRef(function Panel(
  { className, children, ...delegated },
  forwardedRef,
) {
  return (
    <PrimitiveScrollArea.Root
      className={twMerge("relative overflow-hidden", className)}
      {...delegated}
    >
      <PrimitiveScrollArea.Viewport
        ref={forwardedRef}
        className="h-full w-full rounded-[inherit]"
      >
        {children}
      </PrimitiveScrollArea.Viewport>

      {/* Render Vertical bar by default */}
      <Bar />

      <PrimitiveScrollArea.Corner />
    </PrimitiveScrollArea.Root>
  );
});

export function Bar({ className, orientation = "vertical", ...delegated }) {
  return (
    <PrimitiveScrollArea.Scrollbar
      orientation={orientation}
      className={twMerge(
        "flex touch-none select-none rounded-[inherit] p-[1px]",
        orientation === "vertical" &&
          "h-full w-2.5 border-l border-l-transparent",
        orientation === "horizontal" &&
          "h-2.5 flex-col border-t border-t-transparent",
        className,
      )}
      {...delegated}
    >
      <PrimitiveScrollArea.Thumb className="relative flex-1 rounded-[inherit] bg-gray-500 before:absolute before:left-1/2 before:top-1/2 before:w-full before:h-full before:min-h-[44px] before:min-w-[44px] before:-translate-x-1/2 before:-translate-y-1/2" />
    </PrimitiveScrollArea.Scrollbar>
  );
}

Panel.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Bar.propTypes = {
  orientation: PropTypes.oneOf(["vertical", "horizontal"]),
  className: PropTypes.string,
};

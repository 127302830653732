import React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { camelizeKeys } from "humps";
import { concatenateQueryParams } from "@gonoodle/gn-universe-utils";
import { SECTIONS } from "@gonoodle/gn-universe-analytics-schema/src/constants";

import api from "../api";
import { QUERY_KEYS, ROUTE_PREFIX, OAUTH_INITIATORS } from "../constants";
import { useLogEvent } from "../contexts/Analytics";
import { getUtmParamsFromQueryParams } from "../utils/analytics";

const JSURL = require("jsurl");

const client = api();

export default function useLogin({
  redirectRoute = `/${ROUTE_PREFIX.PROFILES}`,
  onSuccess = () => {},
} = {}) {
  const queryClient = useQueryClient();
  const router = useRouter();
  const [errorMessage, setErrorMessage] = React.useState();

  const { logEvent: loginSucceededEvent } = useLogEvent({
    event: "Login Succeeded",
    properties: {
      ...getUtmParamsFromQueryParams(router.query),
    },
    options: {
      referrer: () => ({}),
    },
  });

  const { logEvent: loginFailedEvent } = useLogEvent({
    event: "Login Failed",
    properties: {
      ...getUtmParamsFromQueryParams(router.query),
    },
    options: {
      referrer: () => ({}),
    },
  });

  const { mutate: login, error, isLoading } = useMutation(
    (userCredentials) =>
      client.login(userCredentials.email, userCredentials.password),
    {
      onSuccess: async (...params) => {
        const { oauthInitiator } = camelizeKeys(router.query);

        const pathname =
          typeof redirectRoute === "string"
            ? redirectRoute
            : redirectRoute.pathname;

        await onSuccess(...params);

        await loginSucceededEvent({
          sourceElement:
            oauthInitiator === OAUTH_INITIATORS.CLEVER_LOGIN
              ? SECTIONS.LOGIN_WITH_CLEVER_BUTTON
              : oauthInitiator === OAUTH_INITIATORS.CLEVER_SIGNUP
              ? SECTIONS.REGISTER_WITH_CLEVER_BUTTON
              : oauthInitiator === OAUTH_INITIATORS.GOOGLE_LOGIN
              ? SECTIONS.LOGIN_WITH_GOOGLE_BUTTON
              : oauthInitiator === OAUTH_INITIATORS.GOOGLE_SIGNUP
              ? SECTIONS.REGISTER_WITH_GOOGLE_BUTTON
              : undefined,
          ...getUtmParamsFromQueryParams(router.query),
        });

        await router.replace(
          concatenateQueryParams(pathname, {
            referrer: JSURL.stringify({
              pathname: router.pathname,
              ...redirectRoute.referrer,
            }),
            ...router.query,
            ...redirectRoute.query,
          }),
          pathname,
        );

        await queryClient.invalidateQueries([QUERY_KEYS.USER]);
        queryClient.invalidateQueries([QUERY_KEYS.USER_ACCESSES]);
      },
    },
  );

  React.useEffect(() => {
    const getErrorMessage = async () => {
      try {
        const { error: message } = await error.response.json();
        setErrorMessage(message);
        loginFailedEvent({ failureReason: message });
      } catch {
        /* empty */
      }
    };
    if (error) {
      getErrorMessage();
    } else {
      setErrorMessage(undefined);
    }
  }, [error, loginFailedEvent]);

  return { login, error: errorMessage, isLoading };
}

export default {
  HOME: "/",
  VIDEOS: "videos",
  ACTIVITIES: "activities",
  GAMES: "games",
  TAGS: "tags",
  PROFILES: "profiles",
  CHAMP_COLLECTION: "champ-collection",
  TROPHIES: "trophies",
  ACCOUNT_SETTINGS: "account-settings",
  LOGIN: "login",
  FORGOT_PASSWORD: "forgot-password",
  REGISTRATION: "registration",
  FAVORITES: "favorites",
  COMPANY: "company",
  SEARCH: "search",
  CURRICULUM: "curriculum",
  EXTERNAL_LINK_DISCLAIMER: "external-link-disclaimer",
  FIVE_HUNDRED: "500",
  FOUR_O_FOUR: "404",
};

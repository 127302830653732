import React from "react";
import { StarIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

const SIZES = {
  sm: "h-4 w-4",
  md: "h-5 w-5",
};

export default function Rating({
  value = 0,
  max = 5,
  size = SIZES.sm,
  className = "",
}) {
  // Ensure the max prop is at least 1
  const totalStars = Math.max(1, max);

  // Render stars based on value and max
  const stars = Array.from({ length: totalStars }, (_, index) => (
    <StarIcon
      key={index}
      className={twMerge(
        "rotate-12 shrink-0",
        size,
        index < value ? "text-yellow" : "text-gray-300",
      )}
      fill={index < value ? "#FFE700" : "none"}
    />
  ));

  return (
    <div className={twMerge("flex flex-row flex-wrap space-x-1", className)}>
      {stars}
    </div>
  );
}

Rating.SIZES = SIZES;

Rating.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  size: PropTypes.oneOf(Object.values(SIZES)),
  className: PropTypes.string,
};

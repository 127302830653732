import React from "react";
import { useRouter } from "next/router";
import {
  Button,
  BUTTON_VARIANTS,
  BUTTON_SIZES,
} from "@gonoodle/gn-universe-ui";
import { concatenateQueryParams } from "@gonoodle/gn-universe-utils";

import { ROUTE_PREFIX } from "../../constants";
import { useIntendedPath } from "../../hooks";

export default function RedirectToRegistrationButton({
  children = "Create a Free Account",
  queryParams = {},
  onPress = () => {},
  ...delegated
}) {
  const router = useRouter();
  const { intendedPath } = useIntendedPath();

  const currentIntendedPath = intendedPath || router.asPath;

  return (
    <Button
      variant={BUTTON_VARIANTS.light}
      size={BUTTON_SIZES.sm}
      onPress={(e) => {
        router.push(
          concatenateQueryParams(`/${ROUTE_PREFIX.REGISTRATION}`, {
            intended_path: currentIntendedPath,
            ...queryParams,
          }),
          `/${ROUTE_PREFIX.REGISTRATION}`,
        );

        onPress(e);
      }}
      {...delegated}
    >
      {children}
    </Button>
  );
}

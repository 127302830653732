import React from "react";

import useProfile from "../useProfile";

export default function useEarnedBadges() {
  const {
    profile: {
      earnedBadges: undecoratedEarnedBadges = [],
      claimedBadges = [],
    } = {},
  } = useProfile();

  const earnedBadges = React.useMemo(
    () =>
      undecoratedEarnedBadges
        .map((badge) => ({
          ...badge,
          isEarned: true,
          isClaimed: claimedBadges.some(
            (claimedBadge) => claimedBadge.id === badge.id,
          ),
        }))
        .sort(
          (a, b) => new Date(a.publishStartsAt) - new Date(b.publishStartsAt),
        ),
    [undecoratedEarnedBadges, claimedBadges],
  );

  return {
    earnedBadges,
  };
}

import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { StarIcon } from "@heroicons/react/solid";

const variantsLabels = {
  chip: `Current`,
  limited: `Limited`,
  new: `New`,
  premium: (
    <>
      <StarIcon className="h-4 w-4 shrink-0 text-white stroke-0 mr-[2px]" />
      Premium
    </>
  ),
  earned: `Earned`,
  sponsored: `Sponsored`,
};

const variantsClassNames = {
  chip: `rounded-full gn-text-xs bg-white text-gray-900 font-bold px-[10px]`,
  limited: `rounded-sm gn-text-sm bg-gold text-gray-900 font-bold px-xs`,
  new: `rounded-sm gn-text-sm bg-mint text-gray-900 font-bold px-xs`,
  premium: `rounded-sm gn-text-sm bg-pink text-white font-bold px-xs`,
  earned: `rounded-sm gn-text-sm bg-white text-gray-900 font-bold px-xs`,
  sponsored: `rounded bg-purple gn-text-xs text-white px-xs`,
};

export default function Tag({ variant, label, className = "" }) {
  return (
    <span
      className={twMerge(
        "inline-flex items-center py-[4px]",
        variantsClassNames[variant],
        className,
      )}
    >
      {label || variantsLabels[variant]}
    </span>
  );
}

const VARIANTS = Object.keys(variantsLabels).reduce((acc, key) => {
  acc[key] = key;
  return acc;
}, {});

Tag.VARIANTS = VARIANTS;

Tag.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  variant: PropTypes.oneOf(Object.values(VARIANTS)),
};

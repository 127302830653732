import useBadgesQuery from "../useBadgesQuery";
import useFilteredBadges from "../useFilteredBadges";
import { BADGES_TYPES } from "../../constants";

export default function useClassicBadgesQuery() {
  const {
    badges = [],
    isBadgesLoading: isClassicBadgesLoading,
    badgesError: classicBadgesError,
  } = useBadgesQuery();

  const { filteredBadges: classicBadges } = useFilteredBadges({
    badges,
    types: [
      BADGES_TYPES.EVERGREEN,
      BADGES_TYPES.FEATURED,
      BADGES_TYPES.LIMITED,
    ],
  });

  return { classicBadges, isClassicBadgesLoading, classicBadgesError };
}
